import axios from 'axios';

const defaultOptions = {
	baseURL: 'https://ovcrm-windfall-backup.em5vrs.easypanel.host/'
	// baseURL: 'http://localhost:3001/'
};

export const client = axios.create(defaultOptions);

const defaultAuthOptions = {
	baseUrl: 'https://ovcrm-windfall-backup.em5vrs.easypanel.host/',
	headers: {
		'Content-Type': 'aplication/json',
		Authorization: ''
	}
};

export const authClient = axios.create(defaultAuthOptions);
